import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

import bp from '../assets/js/breakpoints';

const Container = styled.div`
  max-width: 70ch;
  margin: 0 auto var(--space-xxl);

  @media (${bp.min.sm}) {
    margin: 0 auto var(--space-xxxl);
  }
`;

const Title = styled.h1`
  font-size: clamp(3.6rem, 8vw, 7.2rem);
  font-variation-settings: 'wght' 500;
  margin-bottom: var(--space-s);

  @media (${bp.min.sm}) {
    margin-bottom: var(--space-m);
  }

  @media (${bp.min.sm_md}) {
    margin-bottom: var(--space-xl);
  }
`;

const Content = styled.div`
  p {
    font-family: var(--f-sans);
    /* font-size: 2.4rem; */

    & + p {
      margin-top: var(--space-m);
    }
  }
`;

const Legal = () => (
  <Layout>
    <SEO title="Legal" />

    <Container>
      <Title>Legal</Title>
      <Content>
        <p>
          Hendrerit ornare ante pellentesque aliquet felis dictumst vestibulum
          ridiculus suspendisse, lobortis morbi semper facilisi primis himenaeos
          suscipit sagittis, tempus penatibus auctor pulvinar sociosqu laoreet
          parturient quis. Rhoncus etiam felis tempus condimentum dui duis
          gravida, tristique sollicitudin semper viverra nisl vulputate bibendum
          vivamus, at sem mattis nec mi iaculis. Erat suscipit elit ut tristique
          cubilia enim bibendum per hendrerit, sagittis duis adipiscing
          ullamcorper habitasse gravida iaculis phasellus convallis condimentum,
          curabitur lobortis netus semper ad suspendisse velit sociis. A
          adipiscing pharetra arcu odio fermentum torquent cursus aliquet velit,
          et netus porttitor senectus enim curabitur accumsan consectetur, curae
          quam magnis hendrerit mollis dapibus class primis.
        </p>
        <p>
          Fusce est mus molestie tristique nulla nullam facilisis sodales
          vestibulum amet curabitur leo ultrices, euismod pulvinar etiam laoreet
          ornare id odio adipiscing aptent platea viverra.
        </p>
        <p>
          Vivamus tempus magnis fusce primis himenaeos rutrum aliquet porta
          consequat, penatibus eleifend ultrices sem velit sapien egestas. Urna
          convallis fames leo phasellus lacinia tempor tempus elit malesuada
          nec, purus montes ridiculus senectus nunc cum natoque gravida. Risus
          duis class dolor sociosqu blandit taciti ipsum aliquam, congue
          suspendisse mollis enim nisi ante commodo venenatis, tristique
          fermentum diam placerat massa metus morbi. Maecenas odio vehicula
          condimentum amet potenti accumsan adipiscing rutrum at elementum,
          gravida auctor himenaeos duis imperdiet a luctus taciti consequat
          convallis, class congue augue placerat aliquam penatibus velit donec
          tristique. Elit blandit mus per natoque convallis semper, quisque
          maecenas ornare porta facilisi, neque in augue penatibus ultrices.
        </p>
        <p>
          Laoreet ultrices convallis habitant parturient risus mauris mi, massa
          pellentesque euismod velit eros sagittis fermentum, nisl pretium in
          montes metus imperdiet. Nec eget hac massa arcu quisque tempor nibh
          cras aenean, fringilla quis vel nullam congue donec class penatibus
          proin orci, maecenas rutrum est vehicula ullamcorper natoque magna
          suscipit.
        </p>
      </Content>
    </Container>
  </Layout>
);

export default Legal;
